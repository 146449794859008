<template>
  <div class="serviceSubaccount-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="serviceSubaccount-detail">
      <img
        class="detail-img"
        v-if="this.$route.query.shopLogo"
        :src="this.$route.query.shopLogo"
        alt="icon"
      />
      <span class="user-icon" v-else><i class="el-icon-user iconPeople"></i></span>
      <div>
        <div class="detail-title">店铺名称: {{ $route.query.shopName || "0" }}</div>
        <!-- <span class="detail-item">所属公司: {{ $route.query.companyName }}</span> -->
        <span class="detail-item">所属平台: {{ $route.query.platformName || "0" }}</span>
        <span class="detail-item">所属类目: {{ $route.query.categoryName || "0" }}</span>
        <span class="detail-item">所属客户: {{ $route.query.customerName || "0" }}</span>
      </div>
    </div>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>子账号名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.name"
        ></el-input>
      </div>
      <div class="common-input-container">
        <el-date-picker
          v-model="params.datePicker"
          :picker-options="pickerOptions"
          :clearable="false"
          @change="commonFun"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <el-button class="common-screen-btn" type="primary" @click="() => subaccountList()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <el-button class="common-screen-btn" type="primary" @click="addEdit()"
      >新建子账号</el-button
    >
    <!-- <el-button
      class="common-screen-btn"
      type="primary"
      @click="
        () => {
          assignService('', 'checkbox');
        }
      "
      >批量指派</el-button
    > -->
    <el-button
      class="common-screen-btn"
      type="primary"
      @click="
        () => {
          downLoad($route.query.fileUrl);
        }
      "
      >下载交接文档</el-button
    >
    <el-table
      ref="multipleTable"
      class="common-table"
      @selection-change="handleSelectionChange"
      :data="tableData"
    >
      <!-- <el-table-column type="selection" width="55"></el-table-column> -->
      <el-table-column
        prop="subaccount_name"
        label="子账号名称"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="subaccount_password"
        label="子账号密码"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="管理员手机号"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="serviceName"
        label="所属分公司负责人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column label="服务时间" width="200px">
        <template slot-scope="scope">
          <span>{{
            scope.row.startTime && scope.row.endTime
              ? scope.row.startTime + " ~ " + scope.row.endTime
              : "--"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="note"
        label="备注"
      >
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.note" class="item" effect="dark" :content="scope.row.note" placement="bottom">
            <div class="common-overflow-drop">{{scope.row.note}}</div>
          </el-tooltip>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_name"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        :formatter="tableColumn"
        label="创建时间"
      ></el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <!-- <el-button
            type="text"
            size="small"
            @click="() => assignService(scope.row, 'one')"
            >指派分公司</el-button
          > -->
          <el-button type="text" size="small" @click="() => addEdit(scope.row)"
            >编辑</el-button
          >
          &nbsp;&nbsp;&nbsp;
          <el-popconfirm
            placement="top"
            title="确定删除吗？"
            @confirm="() => deleteSubaccount(scope.row.id)"
          >
            <el-button type="text" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      :title="!tableRowId ? '新建子账号' : '修改子账号'"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleFormAdd"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="子账号名称" prop="subaccountName">
            <el-input
              class="dialog-input"
              placeholder="请填写子账号名称"
              v-model="ruleFormAdd.subaccountName"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="子账号密码" prop="subaccountPassword">
            <el-input
              class="dialog-input"
              placeholder="请填写子账号密码"
              v-model="ruleFormAdd.subaccountPassword"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="管理员手机号" prop="phone">
            <el-input
              class="dialog-input"
              placeholder="请填写管理员手机号"
              v-model="ruleFormAdd.phone"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="服务时间" prop="datePicker">
            <el-date-picker
              v-model="ruleFormAdd.datePicker"
              class="dialog-input"
              type="daterange"
              :clearable="false"
              :picker-options="pickerOptions"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              class="dialog-input"
              placeholder="请填写备注"
              v-model="ruleFormAdd.note"
              maxLength="500"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="指派分公司"
      :append-to-body="true"
      :visible.sync="assignVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="assignForm"
          :rules="assignRules"
          ref="assignForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="服务时间" prop="datePicker">
            <el-date-picker
              v-model="assignForm.datePicker"
              class="dialog-input"
              type="daterange"
              :clearable="false"
              :picker-options="pickerOptions"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="分公司负责人" prop="serviceId">
            <el-select
              class="dialog-input"
              v-model="assignForm.serviceId"
              placeholder="请选择负责人"
              filterable
            >
              <el-option
                v-for="item in comPeopleList"
                :key="item.id + ''"
                :label="item.nickName"
                :value="item.id + ''"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="assignVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              submitAssign('assignForm');
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="提示"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="30%"
    >
      <div v-for="(item, index) in tipText" :key="index">{{ item }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="() => isOk()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import {
  subaccountList,
  addEditSubaccount,
  deleteSubaccount,
  assignSubaccount,
} from "../../service/customer.js";
import { getRoleByType } from "../../service/common.js";
import { DateTransform, tableColumn } from "../../utils/index.js";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      tableColumn,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "销售", isLink: false },
        { title: "店铺管理", isLink: true, url: "/customer/customer-list" },
        { title: "子账号管理", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      shopid: this.$route.query.shopid, // 店铺列表传值
      tableRowId: "", // 客户id用于编辑/查看
      centerDialogVisible: false, // 新增修改弹窗
      assignVisible: false, // 指派分公司弹窗
      dialogVisible: false, // 指派确认框
      tipText: [], // 确认框提示语
      comPeopleList: [],
      assignForm: {}, // 指派分公司form表单
      assignRules: {
        serviceId: [{ required: true, message: "请选择分公司负责人", trigger: "change" }],
        datePicker: [{ required: true, message: "请选择服务时间", trigger: "change" }],
      },
      serviceList: [], // 获取客服下拉
      params: {
        pageNum: 1,
        pageSize: 10,
        datePicker: [
          new Date(this.$route.query.startTime),
          new Date(this.$route.query.endTime),
        ],
      },
      ruleFormAdd: {
        //
        shopId: this.$route.query.shopid,
      },
      rules: {
        subaccountName: [
          { required: true, message: "请填写子账号名称", trigger: "change" },
        ],
        subaccountPassword: [
          { required: true, message: "请填写子账号密码", trigger: "change" },
        ],
        datePicker: [{ required: true, message: "请选择服务时间", trigger: "change" }],
        phone: [
          // { validator: rulePhone, trigger: "change" },
          { required: true, message: "请填写手机号" },
        ],
      },
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      tableData: [],
      multipleTable: [],
      pickerOptions: {
        // 指派分公司的禁用时间范围
        disabledDate: (time) => {
          return (
            time.getTime() <
              new Date(this.$route.query.startTime).getTime() - 24 * 60 * 60 * 1000 ||
            time.getTime() > new Date(this.$route.query.endTime).getTime()
          );
        },
      },
    };
  },
  created() {
    this.subaccountList();
    this.getRoleByType(4);
    this.getRoleByType(2);
  },
  methods: {
    downLoad(url) {
      // 下载交接文档
      if (url) {
        window.location.href = url;
      }
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.subaccountList();
    },
    async isOk() {
      let assignForm = { ...this.assignForm };
      assignForm.isForce = "1";
      if (assignForm.datePicker && assignForm.datePicker.length) {
        assignForm.start_time = DateTransform(assignForm.datePicker[0]);
        assignForm.end_time = DateTransform(assignForm.datePicker[1]);
      }
      await assignSubaccount(assignForm);
      this.subaccountList();
      this.$message.success("指派成功");
      this.dialogVisible = false;
    },
    submitAssign(formName) {
      // 新增修改提交
      let assignForm = { ...this.assignForm };
      if (assignForm.datePicker && assignForm.datePicker.length) {
        assignForm.start_time = DateTransform(assignForm.datePicker[0]);
        assignForm.end_time = DateTransform(assignForm.datePicker[1]);
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let resData = (await assignSubaccount(assignForm)).data;
          if (resData.isOk) {
            this.$message.success("指派成功");
            this.subaccountList();
            this.assignVisible = false;
          } else {
            this.tipText = resData.info;
            this.dialogVisible = true;
            this.assignVisible = false;
          }
        } else {
          return false;
        }
      });
    },
    handleSelectionChange(row) {
      console.log(row);
      this.multipleTable = row;
    },
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        datePicker: [
          new Date(this.$route.query.startTime),
          new Date(this.$route.query.endTime),
        ],
      };
      this.subaccountList();
    },
    async getRoleByType(id) {
      // 获取客服下拉
      let resData = (await getRoleByType({}, id)).data;
      if (id == 2) {
        this.comPeopleList = resData;
      } else if (id == 4) {
        this.serviceList = resData;
      }
    },
    async deleteSubaccount(id) {
      await deleteSubaccount({}, id);
      this.$message.success("删除成功");
      this.subaccountList();
    },
    submitForm(formName) {
      // 新增修改提交
      let tableRowId = this.tableRowId;
      let ruleFormAdd = { ...this.ruleFormAdd };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (tableRowId) {
            ruleFormAdd.id = tableRowId;
          }
          if (ruleFormAdd.datePicker && ruleFormAdd.datePicker.length) {
            ruleFormAdd.startTime = DateTransform(ruleFormAdd.datePicker[0]);
            ruleFormAdd.endTime = DateTransform(ruleFormAdd.datePicker[1]);
          }
          await addEditSubaccount(ruleFormAdd);
          if (tableRowId) {
            this.$message.success("修改成功");
          } else {
            this.$message.success("添加成功");
          }
          this.subaccountList();
          this.centerDialogVisible = false;
        } else {
          return false;
        }
      });
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.subaccountList();
    },
    async subaccountList() {
      // 子账号列表
      this.loading = true;
      let params = { ...this.params };
      if (params.datePicker && params.datePicker.length) {
        params.startTime = DateTransform(params.datePicker[0]);
        params.endTime = DateTransform(params.datePicker[1]);
      }
      let resData = (await subaccountList({ ...params, id: this.shopid })).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.loading = false;
    },
    assignService(row, type) {
      let multipleTable = [...this.multipleTable];
      if (type == "one") {
        if (row) {
          this.assignForm = {
            subIds: row.id,
            datePicker:
              row.startTime && row.endTime
                ? [new Date(row.startTime), new Date(row.endTime)]
                : [
                    new Date(this.$route.query.startTime),
                    new Date(this.$route.query.endTime),
                  ],
            serviceId: row.serviceId ? row.serviceId.join(",") : "",
          };
        }
      } else {
        if (!multipleTable || !multipleTable.length) {
          this.$message.success("请先选择");
          return false;
        }
        let newArr = [];
        multipleTable.map((item) => {
          newArr.push(item.id);
        });
        this.assignForm = {
          subIds: newArr.join(","),
          datePicker: [
            new Date(this.$route.query.startTime),
            new Date(this.$route.query.endTime),
          ],
        };
      }
      this.assignVisible = true;
    },
    addEdit(row) {
      // 新增修改弹窗
      if (row) {
        this.tableRowId = row.id;
        this.ruleFormAdd = {
          shopId: this.$route.query.shopid,
          subaccountName: row.subaccount_name,
          subaccountPassword: row.subaccount_password,
          datePicker: [new Date(row.startTime), new Date(row.endTime)],
          // serviceIds: row.serviceId,
          note: row.note,
          id: row.id,
          phone: row.phone,
        };
      } else {
        this.tableRowId = "";
        this.ruleFormAdd = {
          shopId: this.$route.query.shopid,
          datePicker: [
            new Date(this.$route.query.startTime),
            new Date(this.$route.query.endTime),
          ],
        };
        if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
      }
      this.centerDialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.serviceSubaccount-container {
  text-align: left;
  .iconPeople {
    width: 80px;
    height: 80px;
    color: #fff;
    background: #87d068;
    border-radius: 50%;
    font-size: 70px;
    line-height: 80px;
    text-align: center;
    margin-right: 16px;
  }
  .serviceSubaccount-detail {
    display: flex;
    padding: 10px 10px 24px;
    align-items: center;
    color: #000000a6;
    .detail-img {
      width: 82px;
      height: 82px;
      margin-right: 20px;
    }
    .detail-title {
      margin-bottom: 16px;
      font-size: 20px;
    }
    .detail-item {
      margin-right: 16px;
    }
  }
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
</style>
