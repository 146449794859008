import { render, staticRenderFns } from "./serviceSubaccount.vue?vue&type=template&id=508fed94&scoped=true&"
import script from "./serviceSubaccount.vue?vue&type=script&lang=js&"
export * from "./serviceSubaccount.vue?vue&type=script&lang=js&"
import style0 from "./serviceSubaccount.vue?vue&type=style&index=0&id=508fed94&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "508fed94",
  null
  
)

export default component.exports